jQuery(document).ready(function () {
    //jQuery('.cookies').hide();

    if (jQuery.cookie('cookiebar') === undefined || jQuery.cookie('cookiebar') === null) {
        //alert('test');
        jQuery('.cookies').show();

    }
    jQuery('#cookies-accepted').click(function (event) {
        event.preventDefault();
        jQuery('.cookies').fadeOut();
        jQuery.cookie('cookiebar', true, { expires: 365 });
    });


});
